import PROJECTS from '../data/projects';
const ProjectCard = ({ imageSrc, name, year, url }) => {
  return (
    <>
      <div className=" flex flex-col gap-4 w-full">
        <div className=" flex-shrink-0 w-full h-[350px] overflow-hidden rounded-md">
          {/* picture */}
          {!imageSrc ? (
            <div className=" w-full h-full bg-gray-300 " />
          ) : (
            <div
              style={{
                backgroundImage: `url(${imageSrc})`,
              }}
              className=" w-full h-full bg-cover bg-center "
            />
          )}
        </div>
        {/* info */}
        <div className=" flex flex-col justify-center w-full">
          <a href={url}>
            <p className=" text-xl font-semibold text-black">{name}</p>
          </a>
          <div className=" outline-none flex  flex-col justify-start flex-shrink-0 transform-none flex-[0_0_auto] h-auto relative whitespace-pre w-auto">
            <p className=" text-gray-600">{year}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default function ProjectListView() {
  return (
    <>
      {/* project list */}
      <div className=" gap-[30px] p-[0px_30px] w-[810px] justify-start items-start flex flex-[0_0_auto] flex-col md:gap-[50px] h-auto max-w-full overflow-hidden md:p-[0px_50px] relative md:w-full last:border-b">
        {/* project */}

        {PROJECTS.map((project, index) => (
          <ProjectCard
            key={index}
            name={project.title}
            imageSrc={project.img}
            year={project.year}
            url={project.url}
          />
        ))}
      </div>
    </>
  );
}
