import { RecipePic } from '../images';
import { AlpacaImage } from '../images';
const PROJECTS = [
  {
    title: 'Sample Recipe Page (HTML/CSS)',
    description:
      'This is a simple HTML document that presents a recipe for a classic omelette. It provides detailed instructions on how to prepare the dish, along with a list of ingredients and nutritional information per serving.',
    img: RecipePic,
    url: 'https://abmdev86.github.io/recipe-page-main-solution/',
    year: '2024',
  },
  // {
  //   title: 'Quack Planets',
  //   description: 'DJD Hackathon. Webapp displaying the planets in our galaxy',
  //   img: 'https://github.com/guitarkeegan/quack-planets/raw/main/assets/images/quack-planets.gif',
  //   url: 'https://quack-planets.vercel.app/',
  //   year: '2023',
  // },

  {
    title: 'Alpaca Generator',
    description: 'Shortens URLs using the bitly.com API.',
    img: AlpacaImage,
    url: 'https://preeminent-dolphin-92d38e.netlify.app/',
    year: '2022',
  },
  // {
  //   title: 'Pokedex REACT',
  //   description: 'A Pokedex that queries the Pokemon API.',
  //   img: 'https://i.imgur.com/QZG11dH.jpeg',
  //   url: 'https://vigorous-chandrasekhar-8ced84.netlify.app/',
  //   year: '2022',
  // },
 
];
export default PROJECTS;
