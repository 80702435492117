const GAMES = [
  {
    title: 'The I of the Dragon',
    description: '',
    url: 'https://aurmorgames.itch.io/the-i-of-the-dragon',
    img: 'https://img.itch.zone/aW1nLzc4MjQ0NTkucG5n/original/8vF4DM.png',
  },
  {
    title: 'Dodge Box',
    description: '',

    url: 'https://aurmorgames.itch.io/dodge-box',
    img: 'https://img.itch.zone/aW1nLzU5NzcxNjIucG5n/347x500/pQXgjy.png',
  },
  {
    title: "How 'Bout dem Apples",
    description: '',

    url: 'https://aurmorgames.itch.io/how-bout-dem-apples',
    img: 'https://img.itch.zone/aW1hZ2UvMTAwNjI4Ni81NzMxODY5LnBuZw==/original/Lmdg%2Bo.png',
  },
  {
    title: 'Galaxy Wars',
    description: '',

    url: 'https://aurmorgames.itch.io/galaxy-wars',
    img: 'https://img.itch.zone/aW1hZ2UvMTAwNjQyOS81NzMyNzAxLnBuZw==/original/0VYfbe.png',
  },
];

export default GAMES;
